import styled from "styled-components/macro"
import { pxToRem } from "../utils/style-utils"
// import {FlexCol} from "./FlexComponents";

// interface IButtonBase {
//   padding: number;
//   width: number;
// }
// padding: ${(({padding}) => pxToRem(padding || 20))} ${(({padding}) => pxToRem((padding || 32) * 1.6))};
// width: ${(({width}) => pxToRem(width || 280))};

interface IAvatarBase {
  size?: number
}
export const AvatarBase = styled.img<IAvatarBase>`
  min-width: ${({ size }) => pxToRem(size || 60)};
  min-height: ${({ size }) => pxToRem(size || 60)};
  max-width: ${({ size }) => pxToRem(size || 60)};
  max-height: ${({ size }) => pxToRem(size || 60)};
  border-radius: 100%;
  flex: 0 1 0%;
  background-color: rgba(0, 0, 0, 0.1);
`

export default AvatarBase
