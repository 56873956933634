import * as React from "react"

/* tslint:disable */
const defaultStyle = { height: `9.375em` }

const EmptyPageFiller = ({ sportType = "NFL", ...props }) => {
  if (sportType === "NCAAB") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 304 85">
        <g fill="#C4C9CE" fillRule="evenodd" transform="translate(0 .2)">
          <rect width="66" height="21.6" x="118.8" y="32.4" rx="2.4" />
          <rect width="27.6" height="12" x="276" rx="2.4" />
          <rect width="27.6" height="12" rx="2.4" />
          <rect width="27.6" height="12" x="276" y="54" rx="2.4" />
          <rect width="27.6" height="12" y="54" rx="2.4" />
          <rect width="27.6" height="12" x="276" y="18" rx="2.4" />
          <rect width="27.6" height="12" y="18" rx="2.4" />
          <rect width="27.6" height="12" x="276" y="72" rx="2.4" />
          <rect width="27.6" height="12" y="72" rx="2.4" />
          <g fillRule="nonzero">
            <path d="M90.86 70.2h1.2V15h-1.2zm-35.4-45h1.2V6h-1.2zm0 54h1.2V60h-1.2z" />
            <path d="M91.45 16.2V15h-36v1.2zm0 54V69h-36v1.2zm-35.48-63V6H27.86v1.2zm0 54V60H27.86v1.2zm-.26-36V24H27.6v1.2zm63.85 18V42h-28.1v1.2zm-63.85 36V78H27.6v1.2z" />
          </g>
          <g fillRule="nonzero">
            <path d="M212.75 70.2h-1.2V15h1.2zm35.4-45h-1.2V6h1.2zm0 54h-1.2V60h1.2z" />
            <path d="M212.15 16.2V15h36v1.2zm0 54V69h36v1.2zm35.48-63V6h28.11v1.2zm0 54V60h28.11v1.2zm.26-36V24H276v1.2zm-63.85 18V42h28.1v1.2zm63.85 36V78H276v1.2z" />
          </g>
        </g>
      </svg>
    )
  } else {
    return (
      <svg viewBox="0 0 332 128" xmlns="http://www.w3.org/2000/svg" style={props.style || defaultStyle} {...props}>
        <g transform="translate(.923 .923)" fill="none" fillRule="evenodd" opacity=".5">
          <g stroke="#959EA6" strokeWidth="4.408">
            {/* tslint:disable-next-line */}
            <path d="M91.336 102.387c-4.624 0-8.371-3.606-8.371-8.057 0-4.45 3.747-8.058 8.371-8.058 4.623 0 8.37 3.608 8.37 8.058 0 4.45-3.747 8.057-8.37 8.057zm60.985-4.418c0-1.243-.904-2.237-2.193-2.486l-33.641-5.023.006-6.108-.01-7.892c0-2.735 2.325-4.972 5.164-4.972h25.82c1.42 0 2.583-1.118 2.583-2.487 0-28.83-24.273-52.194-54.224-52.194-29.95 0-54.221 23.363-54.221 52.194 0 11.436 3.745 22.248 10.844 31.32.518.62 1.29.992 2.066.992h11.878c.362.2.7.386 1.043.573 3.137 9.732 12.562 16.8 23.704 16.8 11.766 0 22.245-7.443 24.829-18.001 9.99 1.56 31.752 4.953 33.685 5.198 2.978.376 2.658-2.491 2.658-2.491l.009-5.423zM241.445 102.387c-4.624 0-8.371-3.606-8.371-8.057 0-4.45 3.747-8.058 8.371-8.058 4.623 0 8.37 3.608 8.37 8.058 0 4.45-3.747 8.057-8.37 8.057zm-4.492-85.58c-29.95 0-54.224 23.363-54.224 52.194 0 1.37 1.164 2.487 2.584 2.487h25.82c2.839 0 5.164 2.237 5.164 4.972l-.01 7.892.006 6.108-33.64 5.023c-1.29.25-2.194 1.243-2.194 2.486l.009 5.423s-.32 2.867 2.658 2.49c1.933-.244 23.695-3.636 33.685-5.197 2.583 10.558 13.062 18.002 24.828 18.002 11.143 0 20.568-7.07 23.705-16.8.344-.188.68-.373 1.042-.574h11.879c.776 0 1.55-.372 2.067-.992 7.098-9.072 10.843-19.884 10.843-31.32 0-28.83-24.27-52.194-54.222-52.194z" />
          </g>
          <g fill="#959EA6">
            {/* tslint:disable-next-line */}
            <path d="M15.038 2.82a2.82 2.82 0 1 0-5.638 0 2.82 2.82 0 0 0 5.638 0M33.836 2.82a2.82 2.82 0 1 0-5.639 0 2.82 2.82 0 0 0 5.639 0M43.235 2.82a2.82 2.82 0 1 0-5.64 0 2.82 2.82 0 0 0 5.64 0M52.634 2.82a2.82 2.82 0 1 0-5.639 0 2.82 2.82 0 0 0 5.639 0M52.634 11.749a2.82 2.82 0 1 0-5.639 0 2.82 2.82 0 0 0 5.639 0M43.747 20.635a2.82 2.82 0 1 0-5.639 0 2.82 2.82 0 0 0 5.639 0M62.032 2.82a2.82 2.82 0 1 0-5.638 0 2.82 2.82 0 0 0 5.638 0M71.432 2.82a2.82 2.82 0 1 0-5.639 0 2.82 2.82 0 0 0 5.639 0M80.83 2.82a2.82 2.82 0 1 0-5.638 0 2.82 2.82 0 0 0 5.639 0M100.568 2.82a2.82 2.82 0 1 0-5.639 0 2.82 2.82 0 0 0 5.639 0M5.639 2.82A2.82 2.82 0 1 0 0 2.82a2.82 2.82 0 0 0 5.639 0" />
          </g>
          <g transform="translate(291.785 76.872)" fill="#959EA6">
            {/* tslint:disable-next-line */}
            <path d="M7.11 35.83a6.497 6.497 0 0 0-6.49 6.49 6.498 6.498 0 0 0 6.49 6.491 6.499 6.499 0 0 0 6.491-6.491c0-3.58-2.912-6.49-6.49-6.49m0 13.601C3.19 49.431 0 46.241 0 42.32c0-3.92 3.19-7.11 7.11-7.11 3.922 0 7.111 3.19 7.111 7.11a7.119 7.119 0 0 1-7.11 7.111" />
            <path d="M12.002 37.934l-.438-.438c8.604-8.604 14.021-19.983 15.254-32.04l.616.063c-1.246 12.198-6.727 23.71-15.432 32.415" />
            <polygon points="28.8141987 6.81354588 27.4048701 5.68434189e-14 25.2177244 6.60524629" />
          </g>
          <polygon fill="#959EA6" points="31.4102564 114.068826 31.4102564 119.264744 31.4102564 124.460661 51.7822874 119.264744" />
          <g transform="translate(290.958 4.96)">
            {/* tslint:disable-next-line */}
            <path d="M26.263 20.73a4.741 4.741 0 1 1-9.483 0 4.741 4.741 0 0 1 9.483 0z" stroke="#959EA6" strokeWidth="1.317" />
            {/* tslint:disable-next-line */}
            <path
              d="M21.522 9.47c-6.209 0-11.26 5.051-11.26 11.26s5.051 11.26 11.26 11.26c6.208 0 11.26-5.051 11.26-11.26S27.73 9.47 21.522 9.47zm0 28.314c-9.404 0-17.054-7.65-17.054-17.054 0-9.404 7.65-17.054 17.054-17.054 9.403 0 17.054 7.65 17.054 17.054 0 9.404-7.65 17.054-17.054 17.054z"
              stroke="#959EA6"
              strokeWidth="1.317"
            />
            {/* tslint:disable-next-line */}
            <path d="M22.213 20.829a1.053 1.053 0 1 1-2.107 0 1.053 1.053 0 0 1 2.107 0" fill="#959EA6" />
            {/* tslint:disable-next-line */}
            <polygon fill="#959EA6" points="21.7184663 20.2255909 9.52234846 8.04922705 8.26467731 9.11725983 20.5542974 21.3871259" />
            {/* tslint:disable-next-line */}
            <polygon
              stroke="#959EA6"
              strokeWidth="1.317"
              points="9.34969853 7.901533 9.37932952 5.04049575 4.15768962 0.0987041389 4.11884009 3.85591439 6.30297371 5.92284096 6.19037592 6.03543874 3.96344198 4.13971348 0.146969739 4.50713783 5.62870394 9.17105655 8.14272931 8.92939932"
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default EmptyPageFiller
