import styled from "styled-components/macro"

export const BackgroundImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
`

export default BackgroundImage
