import styled from "styled-components/macro"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"

export const cardSpacer = pxToRem(16)
// const cardRowH = pxToRem(32);

export const CardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0 ${cardSpacer} ${cardSpacer};
  font-weight: ${fontWeight.semiBold};
  color: ${palette.gray1};
`
export default CardHeader
