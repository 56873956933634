import * as React from "react"

const Svg2 = ({ color = "currentColor", outlineColor = "#000", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 729 660" {...props}>
    <g fillRule="nonzero" fill="none">
      {/* tslint:disable-next-line */}
      <path
        d="M24.7 440.3C2.5 292.9 48.8 36.7 310.7 21.7 657.6 1.8 638.4 335 638.4 335s-163.7 2.8-163.7 166.7c0 40.2-.3 130.3-156.9 130.3-163.8-.1-121-191.7-293.1-191.7"
        fill={color}
      />
      {/* tslint:disable-next-line */}
      <path
        d="M319.2 426.6c-33 0-59.9 26.9-59.9 59.9s26.9 59.9 59.9 59.9 59.9-26.9 59.9-59.9-26.9-59.9-59.9-59.9zm0 79.9c-11 0-20-9-20-20s8.9-20 20-20c11 0 20 9 20 20s-9 20-20 20z"
        fill={outlineColor}
      />
      {/* tslint:disable-next-line */}
      <path
        d="M709 483.7H574.7c-2.5-18.4-3.8-38.5-3.8-60.2l-.3-51.5c33.2-16 65.3-17 68.1-17.1 10.4-.2 19-8.4 19.6-18.8.3-6 7.5-148.8-85.2-247C512.3 24.7 423.5-4.8 309.6 1.7c-96.4 5.5-172.7 42.2-227 109.1C3.1 208.6-8.9 351 5 443.2c1.5 9.8 9.9 17 19.7 17 76.3 0 103.1 41 134.1 88.5 31.5 48.3 67.3 103.1 159 103.1 150.8 0 173-79.3 176.3-128.2h47.3c11.2 46.9 31.5 82.3 60.7 105.8 31.3 25.2 65.4 30.1 87.4 30.1 13.7 0 22.7-1.9 23.6-2.1 9.1-2 15.7-10.1 15.7-19.5V503.7c.1-11.1-8.8-20-19.8-20zm-254.2 17.9c0 41.3 0 110.3-137 110.3-70.1 0-95.8-39.4-125.6-85-30.6-46.8-65-99.6-149.9-106-9-83.9 4.4-202.8 71.2-285 46.9-57.8 113.7-89.5 198.3-94.4 101.4-5.8 179.6 19.4 232 74.7 63.3 66.9 73.3 161.6 74.6 200.1-65.6 8.4-163.6 54-163.6 185.3zm40.8-17.9c3.4-37.7 17.3-64.4 35.3-83.2l.1 23.1c0 21.4 1.1 41.5 3.4 60.1h-38.8zm193.4 136c-15.5 0-39.9-3.6-62.3-21.8-19.8-16.1-34.5-41.2-43.9-74.3H689v96.1z"
        fill={outlineColor}
      />
    </g>
  </svg>
)

export default Svg2
