import styled from "styled-components/macro"
import { palette, pxToRem } from "../../../utils/style-utils"

const cardSpacer = pxToRem(16)

export const CardDivider = styled.hr<{ isFullWidth?: boolean }>`
  margin: 0 ${({ isFullWidth }) => (isFullWidth === false ? cardSpacer : 0)};
  height: ${pxToRem(1)};
  background-color: ${palette.gray3};
  padding: 0 !important;
`
export default CardDivider
