import * as React from "react"
import styled from "styled-components/macro"
import { getThemeValues } from "../../../../common/theme-helpers"
import maLogo from "../../../assets/ma.png"
import BackgroundImage from "../../../components/BackgroundImage"
// import themes from "../../../../common/themes"
import Link from "../../../components/Link"
import { fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"
import { HorizontalOverflowContainer } from "../../../components/OverflowContainer"

const pickRowH = 100
export const mABP = pxToRem(layout.matchupAnalysisBP)
const watchTeamImgH = 120

export const MaMobileTeamOverview = styled.div`
  position: relative;
  & > *:first-child {
    height: ${pxToRem(89)};
    display: flex;
    flex-flow: row nowrap;
  }
  & img {
    flex: 0 0 30%;
  }
`
export const MaSplitRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  & > * {
    flex: 1 1 50%;
  }
  & > *:last-child {
    border-left: 1px solid #979797;
  }
`
export const MaBracketPlayerS = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0 ${pxToRem(8)};
  & > * {
    height: ${pxToRem(79)};
  }
`
export const MaBracketPlayerInfo = styled.div`
  padding: 0 ${pxToRem(8)};
  flex: 1 1 100%;
  font-size: ${pxToRem(14)};
  display: flex;
  flex-flow: column;
  justify-content: center;
  letter-spacing: ${pxToRem(0.02)};
  & h5 {
    font-weight: ${fontWeight.bold};
    color: #000000;
  }
  & div {
    color: #9b9b9b;
    padding-top: ${pxToRem(4)};
  }
`
export const MaBracketPlayerStats = styled.div`
  flex: 0 0 ${pxToRem(140)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`
export const MaBracketPlayerJersey = styled.div`
  flex: 0 0 ${pxToRem(77)};
  position: relative;
  padding: ${pxToRem(13)};
  font-size: ${pxToRem(14)};
  color: white;
  font-weight: ${fontWeight.extraBold};

  & span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img {
    position: absolute;
    right: 5%;
    bottom: 5%;
    height: ${pxToRem(30)};
    width: ${pxToRem(30)};
  }
`

export const PlayerStat = styled.div`
  flex: 1 1 100%;
  text-align: center;
  letter-spacing: ${pxToRem(14)};
  font-size: ${pxToRem(14)};
  color: #798890;
  display: flex;
  flex-flow: column;
  justify-content: center;
  & > * {
    display: block;
  }
  & span {
    line-height: 1.2em;
    letter-spacing: 0.015em;
  }
  & strong {
    line-height: 1.2em;
    font-size: 1.4em;
    color: #0b1c25;
    letter-spacing: 0.04em;
    white-space: nowrap;
    padding: 0;
  }
`
export const MaBracketPlayerInjury = styled.p`
  padding: 0 7% ${pxToRem(8)};
  font-size: ${pxToRem(13)};
  color: #798890;
`
export const MaMobileVenueInfo = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  color: #4a4a4a;
  font-size: ${pxToRem(16)};
  height: ${pxToRem(70)};
  & strong {
    font-weight: ${fontWeight.semiBold};
    padding-right: 0.5em;
  }
  & div {
    color: #9b9b9b;
  }
  @media (min-width: ${mABP}) {
    display: none;
  }
  .mobile-weather-icon {
    display: inline;
    img {
      filter: none;
    }
  }
`
// export const SSS = styled.div`
// height: ${pxToRem(2)};
// position: sticky;
// left: 0;
// width: 100%;
// top: ${pxToRem(pickRowH)};
// background-color: rgba(231, 231, 231, 0.6);
// z-index: ${zIndexes.ui1};
// `;
// export const SSR = styled.div`
// height: 0;
// position: relative;
// z-index: ${zIndexes.ui0};
// &:after {
//   content: '';
//   top: -${pxToRem(3)};
//   height: ${pxToRem(3)};
//   left: 0;
//   right: 0;
//   position: absolute;
//   z-index: ${zIndexes.ui2};
//   background-color: #fff;
// }
// `;
export const MaTeamsMobile = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(30)};
  &.is-bracket--false:after {
    content: "@";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: ${pxToRem(-20)};
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    background-color: #fff;
    font-size: ${pxToRem(22)};
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    font-weight: ${fontWeight.bold};
    color: #9b9b9b;
  }
  & > * {
    height: ${pxToRem(80)};
    justify-content: space-evenly;
    display: flex;
    flex-flow: column;
  }
  & > * > * {
    padding: 0 !important;
  }
  & h4 {
    font-size: ${pxToRem(18)} !important;
  }
  @media (min-width: ${mABP}) {
    display: none;
  }
`
export const MaInjury = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  & > div {
    flex: 1 1 100%;
  }
  & .team-img {
    flex: 0 1 ${pxToRem(60)};
    padding: ${pxToRem(10)} !important;
  }
`
export const MaInjuries = styled.div`
  color: #000000;
  font-size: ${pxToRem(14)};
  letter-spacing: ${pxToRem(0.2)};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  & *:not(div) {
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    line-height: 1.3em;
  }
  & img {
    margin-right: ${pxToRem(16)};
  }
  & i {
    font-family: Helvetica, inherit;
    color: #9b9b9b;
    margin-right: ${pxToRem(6)};
  }
  & span {
    color: #4a4a4a;
  }
  & strong {
    font-weight: ${fontWeight.bold};
    color: #000000;
  }
`
export const MaInjuryHeader = styled.div`
  &.side__home {
    flex-direction: row-reversed;
  }
  & img {
    width: 30%;
    padding: 0 ${pxToRem(8)};
  }
  & h4 {
    flex: 1 1 100%;
    text-transform: uppercase;
    color: #000000;
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(16)};
    letter-spacing: ${pxToRem(2)};
  }
`
export const MaInjuryCol = styled.ul`
  width: ${pxToRem(250)};
  margin: ${pxToRem(16)} auto;
  & > * {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: ${pxToRem(55 + 12)};
    padding: ${pxToRem(6)};
  }
`
export const MaKeyStatWrapper = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(6)};
  & strong {
    font-weight: ${fontWeight.bold};
  }
`
export const MaKeyStatRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: ${pxToRem(500)};
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  height: ${pxToRem(30)};
  & div {
    flex: 1 1 100%;
    font-size: ${pxToRem(14)};
    letter-spacing: ${pxToRem(0.2)};
    color: #798890;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: ${pxToRem(500)}) {
      font-size: ${pxToRem(14)};
    }
  }
  & span {
    display: block;
    min-width: ${pxToRem(25)};
    font-size: ${pxToRem(16)};
  }
  & svg {
    display: block;
    height: ${pxToRem(25)};
    width: ${pxToRem(25)};
  }
  & strong {
    display: block;
    min-width: ${pxToRem(60)};
    font-weight: ${fontWeight.extraBold};
    font-size: ${pxToRem(18)};
    letter-spacing: ${pxToRem(0.6)};
    color: #0b1c25;
    padding: 0 !important;
    &.right {
      text-align: right;
    }
  }
`
export const BarChartOuter = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} 0 rgba(0, 0, 0, 0.24);
  height: ${pxToRem(20)};
  border-radius: ${pxToRem(10)};
  overflow: hidden;
  width: 90%;
  margin: ${pxToRem(6)} auto;
`
export const BarChartInner = styled.div`
  background-image: linear-gradient(90deg, #24b600 0%, #30de11 100%);
  transition: all 0.5s ease;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-width: 5%;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${pxToRem(5)};
  font-size: ${pxToRem(13)};
  font-weight: ${fontWeight.semiBold};
`

export const expertW = 110
export const MaExperts = styled(HorizontalOverflowContainer)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  scroll-snap-points-x: ${pxToRem(expertW)};
  scroll-snap-type: x mandatory;
  & > * {
    min-height: ${pxToRem(140)};
  }
`
export const MaExpert = styled.li`
  flex: 1 0 ${pxToRem(expertW)};
  flex-shrink: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 ${pxToRem(8)} ${pxToRem(4)};
  scroll-snap-align: start;
  font-size: ${pxToRem(14)};
  letter-spacing: ${pxToRem(0.1)};
  color: #798890;
  text-transform: uppercase;
  & img {
    width: 50%;
  }
  & strong {
    display: block;
    font-weight: ${fontWeight.bold};
    color: #000;
  }
  & small {
    display: block;
    font-size: ${pxToRem(11)};
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #000;
  }
`
export const MaExpertRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0 ${pxToRem(8)};
  margin-bottom: ${pxToRem(12)};
  & > * {
    height: ${pxToRem(60)};
  }
  &.sportsline-full.is-coming-soon--true .sportsline-col--right {
    visibility: hidden;
  }
  &.sportsline-full.is-bracket--true {
    margin-top: ${pxToRem(20)};
    align-items: center;
  }
  @media (min-width: ${mABP}) {
    &.sportsline-full.is-bracket--true.has-overview--false {
      margin-top: 0;
    }
  }
  @media (max-width: ${mABP}) {
    &.sportsline-full.is-coming-soon--true .sportsline-col--right {
      display: none;
    }
  }

  &.sportsline-teaser {
    align-items: center;
  }

  & > [class*="sportsline-col"] {
    height: auto;
    flex: 1 1 100%;
  }
  @media (min-width: ${mABP}) {
    & > [class*="sportsline-col"] {
      max-width: 50%;
    }
  }
  & > .sportsline-col--left {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    & svg {
      width: 15%;
      height: auto;
      padding: ${pxToRem(8)} 3%;
    }
  }
  & > .sportsline-col--right {
    display: flex;
    flex-flow: row wrap;
  }
  & .pick {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    & img {
      max-width: ${pxToRem(70)};
    }
    & div {
      font-size: ${pxToRem(22)};
      font-weight: ${fontWeight.bold};
      padding-bottom: ${pxToRem(12)};
    }
  }
  & strong {
    font-weight: ${fontWeight.semiBold};
  }
  & .simulated-score {
    font-size: ${pxToRem(16)};
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    & p {
      padding-bottom: 0.5em;
    }
  }
  & .confidence {
    flex: 1 0 100%;
    padding-top: ${pxToRem(8)};
    & p {
      font-size: ${pxToRem(15)};
      text-align: center;
    }
  }
`
export const MaExpertRowTeams = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0 ${pxToRem(8)};
  margin-bottom: ${pxToRem(12)};
  justify-content: space-evenly;
  & > * {
    height: ${pxToRem(65)};
  }
`
export const MaExpertTeam = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: ${pxToRem(120)};
  font-size: ${pxToRem(24)};
  font-weight: ${fontWeight.bold};
  letter-spacing: ${pxToRem(1.3)};
  color: #4a4a4a;
  & img {
    height: 85%;
  }
`
export const MaExpertIcon = styled.div`
  height: 100%;
  width: ${pxToRem(70)};
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 ${pxToRem(12)};
  color: #0076ff;
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.bold};
  letter-spacing: ${pxToRem(1)};
  & svg {
    margin: ${pxToRem(6)} 0;
  }
  &.sl-pick {
    flex-flow: column;
  }
  &.sl-pick div {
    color: #0b1c25;
    white-space: nowrap;
  }
  @media (min-width: ${mABP}) {
    & > .is-bracket--false {
      position: absolute;
      right: -80%;
      bottom: ${pxToRem(5)};
    }
  }
  @media (max-width: ${pxToRem(400)}) {
    font-size: ${pxToRem(14)};
  }
  @media (max-width: ${pxToRem(400)}) {
    font-size: ${pxToRem(14)};
  }
  @media (min-width: ${mABP}) {
    &.sportsline {
      width: 20%;
    }
    &.sportsline .sports-line-svg {
      height: ${pxToRem(34)};
      width: ${pxToRem(50)};
    }
    &.sportsline .sports-line-text-svg {
      width: ${pxToRem(85)};
    }
  }
`
export const MaExpertText = styled.div`
  flex: 1 1 ${pxToRem(250)};
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: ${pxToRem(14)};
  letter-spacing: ${pxToRem(0.1)};
  &.sl-green {
    color: #13c503;
    flex: 0;
    white-space: nowrap;
    padding-right: ${pxToRem(16)};
  }
  &.variable-text {
    height: auto !important;
    line-height: 1.2em;
    display: block;
    padding: ${pxToRem(8)} 3%;
  }
`
export const MaExpertMain = styled.div`
  min-width: ${pxToRem(250)};
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: flex-end;
  font-weight: ${fontWeight.semiBold};
  font-size: ${pxToRem(13)};
  letter-spacing: ${pxToRem(3.25)};
  line-height: ${pxToRem(12)};
  color: #9b9b9b;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  & .lock {
    position: absolute;
    left: 0;
    top: 0;
    width: ${pxToRem(45)};
    height: 100%;
  }
  & a {
    position: relative;
    margin: 0 auto;
    padding-left: ${pxToRem(45)};
    max-width: ${pxToRem(320)};
    justify-content: center;
    align-items: center;
    font-size: ${pxToRem(13)};
    color: inherit;
    text-transform: uppercase;
    white-space: nowrap;
    & svg {
      color: #40ce22;
    }
  }
  & .expert-cta {
    height: 100%;
  }
  @media (max-width: ${pxToRem(600)}) {
    p {
      width: 100%;
    }
  }
`
export const MaWatchTeamImg = styled.img`
  position: absolute;
  opacity: 0.2;
  top: ${pxToRem(20 - watchTeamImgH / 2)};
  height: ${pxToRem(watchTeamImgH)};
  width: ${pxToRem(watchTeamImgH)};
  margin: 0 6%;
  &.side__home {
    right: 0;
  }
  &.side__away {
    left: 0;
  }
  @media (max-width: ${mABP}) {
    display: none;
  }
`
export const MaOdds = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  color: ${palette.black};
  margin-top: ${pxToRem(12)};
  & > * {
    height: ${pxToRem(45)};
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
`
export const MaOdd = styled.div`
  flex: 1 1 15%;
  color: #1378d7;
  @media (max-width: ${mABP}) {
    margin: 0 3%;
  }
`
export const MaOddCenter = styled.div`
  flex: 1 1 100%;
  display: flex;
  font-size: ${pxToRem(16)};
  & span {
    padding-right: 0.5em;
    font-weight: ${fontWeight.regular};
  }
  & div {
    padding-bottom: 0.5em;
  }
  @media (max-width: ${mABP}) {
    & {
      font-size: ${pxToRem(14)};
    }
  }
  @media (max-width: ${pxToRem(450)}) {
    & {
      font-size: ${pxToRem(11)};
    }
  }
`
export const MaSectionHeader = ({ children, gray = false }) => (
  <MaSectionHeaderS className={gray ? "gray-bg" : ""}>
    <h2 className={gray ? "gray-bg" : ""}>{children}</h2>
  </MaSectionHeaderS>
)
export const MaSectionHeaderAltH = styled.h5`
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  font-size: ${pxToRem(16)};
  color: #798890;
  letter-spacing: ${pxToRem(2)};
  padding: ${pxToRem(20)} ${pxToRem(20)};
  text-align: center;
  @media (min-width: ${mABP}) {
    &.side__home {
      background: white;
      transform: translateX(-50%);
    }
    &.side__away {
      visibility: hidden;
    }
  }
`
export const MaSectionHeaderAltS = styled.div`
  color: #798890;
  position: relative;
  &.bg__gray {
    background-color: ${palette.matchupAnalysisGrey};
  }
`
export const MaTeamOverviewTeam = styled.div`
  font-size: ${pxToRem(13)};
  & > div {
    position: absolute;
    top: 0;
    display: flex;
    color: #b6b6b6;
    padding: ${pxToRem(12)};
  }
  &.side__left > div {
    left: 0;
  }
  & strong {
    color: #4a4a4a;
    font-weight: ${fontWeight.bold};
  }
  & > p {
    letter-spacing: ${pxToRem(0.1)};
    padding: ${pxToRem(10)} 7%;
    line-height: 1.2em;
  }
`
export const MaTeamsSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  padding-bottom: ${pxToRem(24)};
  & > * {
    flex: 1 1 50%;
  }
  & > *:last-child {
    border-left: 1px solid #9797975e;
  }
  @media (max-width: ${mABP}) {
    display: block;
    background-color: ${palette.matchupAnalysisGrey};
  }
`
export const MaTeamSection = styled.div`
  @media (max-width: ${mABP}) {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0) 100%);
    padding-bottom: ${pxToRem(24)};
    border: none !important;
  }
`

export const MaSectionSubHeader = styled.div`
  text-align: center;
  font-weight: ${fontWeight.light};
  font-style: italic;
  font-size: ${pxToRem(14)};
  color: #9b9b9b;
  letter-spacing: ${pxToRem(0.5)};
`
export const MaTeamOverviewSection = styled.div``
export const MaSectionHeaderS = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: ${pxToRem(16)} ${pxToRem(8)} 0;
  & > * {
    background-color: #fff;
    padding: ${pxToRem(8)} ${pxToRem(20)};
    z-index: ${zIndexes.ui1};
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(24)};
    color: #000000;
    letter-spacing: ${pxToRem(1)};
    @media (max-width: ${mABP}) {
      font-size: ${pxToRem(16)};
    }
  }
  &.gray-bg:before {
    display: none;
  }
  &:before {
    content: "";
    position: absolute;
    height: ${pxToRem(1)};
    border-top: 1px solid #e7e7e7;
    width: 95%;
    @media (max-width: ${mABP}) {
      display: none;
    }
  }
`
export const MaWatchRow = styled(Link)`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  font-size: ${pxToRem(13)};
  color: #ffffff;
  letter-spacing: ${pxToRem(2.5)};
  background-color: #0d4fac;
  height: ${pxToRem(40)};
  background-image: linear-gradient(0deg, #2248b7 2%, #0055a6 100%);
  & * {
    transition: all 0.2s ease-out;
  }
  @media (hover: hover) {
    &:hover:not(:active) div {
      transform: translateX(0.9em);
    }
  }
  &:active div {
    transform: translateX(0.7em);
  }
  & svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    margin-right: ${pxToRem(16)};
  }
  @media (max-width: ${mABP}) {
    height: ${pxToRem(64)};
  }
`
export const MaStat = styled.div`
  flex: 1 1 100%;
  text-align: center;
  letter-spacing: ${pxToRem(14)};
  font-size: ${pxToRem(14)};
  color: #798890;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @media (max-width: ${mABP}) {
    justify-content: space-between;
    align-items: center;
    flex-flow: row-reverse nowrap;
    font-size: ${pxToRem(10)};
    padding: 0 ${pxToRem(14)};
    border-top: 1px solid rgba(231, 231, 231, 0.6);
  }
  & > * {
    display: block;
  }
  & span {
    line-height: 1.4em;
    font-size: 0.8em;
    letter-spacing: 0.015em;
    @media (max-width: ${mABP}) {
      font-size: 1em;
      color: #afafb0;
      letter-spacing: ${pxToRem(0.9)};
    }
  }
  & strong {
    line-height: 1.2em;
    font-size: 1.4em;
    color: #0b1c25;
    letter-spacing: 0.04em;
    white-space: nowrap;
    padding: 0 !important;
    @media (min-width: ${mABP}) {
      font-weight: ${fontWeight.extraBold};
    }
    @media (max-width: ${mABP}) {
      color: #000000;
    }
  }
`
export const MaRecords = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
export const MaRecord = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  flex: 1 1 100%;
  & > * {
    height: ${pxToRem(60)};
  }
  @media (max-width: ${mABP}) {
    display: block;
    &:last-child > * {
      flex-direction: row;
    }
    & > * {
      height: ${pxToRem(30)};
    }
  }
`
export const MaTeamImg = styled.img`
  flex: 0 1 0%;
  min-width: ${pxToRem(pickRowH)};
  max-width: ${pxToRem(pickRowH)};
  padding: ${pxToRem(20)};
  @media (max-width: ${mABP}) {
    flex: 1 1 100%;
    max-width: unset;
    padding-top: ${pxToRem(6)};
    padding-bottom: ${pxToRem(6)};
  }
`
export const MaTeamInfo = styled.div`
  flex: 1 1 100%;
  color: ${palette.black};
  font-size: ${pxToRem(16)};
  letter-spacing: ${pxToRem(0.7)};
  font-weight: ${fontWeight.semiBold};
  & h3 {
    font-size: ${pxToRem(19)};
    text-transform: uppercase;
    letter-spacing: ${pxToRem(0.9)};
    padding: ${pxToRem(20)} 0 ${pxToRem(4)};
    white-space: nowrap;
  }
  & div {
    font-weight: ${fontWeight.regular};
    font-size: ${pxToRem(18)};
    letter-spacing: ${pxToRem(0.3)};
    padding: ${pxToRem(8)} 0 ${pxToRem(15)};
  }
  &.is-bracket--true div {
    font-size: ${pxToRem(14)};
  }
  &.is-bracket--false div {
    color: #0076ff;
  }
  & i {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    pointer-events: none;
    color: #81919a;
    padding: ${pxToRem(8)};
    .is-bracket--true & {
      color: #81919a;
    }
  }
  .is-bracket--true & div {
    color: #81919a;
  }
  &.side__home {
    text-align: right;
  }
  &.in-picks {
    overflow: hidden;
  }
  @media (max-width: ${mABP}) {
    &.in-picks {
      display: none;
    }
  }
  @media (min-width: ${mABP}) {
    &.in-content {
      display: none;
    }
  }
`
export const MaSpread = styled.div`
  font-weight: ${fontWeight.bold};
  color: ${palette.blue2};
  font-size: ${pxToRem(16)};
  letter-spacing: ${pxToRem(0.4)};
`
export const MaPicks = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: ${zIndexes.ui2};
`
export const MaPicksDivider = styled.div`
  min-width: 1px;
  flex: 0 1 auto;
  background-color: #e7e7e7;
  opacity: 0.7;
`
export const MaHr = styled.hr`
  height: 1px;
  background-color: rgba(231, 231, 231, 0.6);
  @media (min-width: ${mABP}) {
    display: none;
  }
`
export const MaWrapper = styled.div`
  width: 100vw;
  max-width: ${pxToRem(layout.useMobileNavW)};
  position: relative;
  padding-bottom: ${pxToRem(60)};
  margin-top: ${pxToRem(30)};
  & .gray-bg {
    @media (min-width: ${mABP}) {
      background-color: ${palette.matchupAnalysisGrey} !important;
    }
  }
  @media (min-width: ${mABP}) {
    & .small-only {
      display: none !important;
    }
  }
  @media (max-width: ${mABP}) {
    & .large-only {
      display: none !important;
    }
  }
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    max-width: 100%;
    margin-top: 0;
  }
  & strong {
    font-weight: ${fontWeight.bold};
    padding-right: 0.5em;
  }
`
export const MaContent = styled.section`
  background-color: #fff;
  box-shadow: 0 0 ${pxToRem(15)} ${pxToRem(3)} rgba(0, 0, 0, 0.6);
`
export const logoW = 265
export const headerH = 131
const maColorPrimary = ({ theme }) => getThemeValues(theme, true).primary
const maColorSecondary = ({ theme }) => getThemeValues(theme, true).secondary
const maLogoHeight = ({ theme }) => (getThemeValues(theme, true).isCircularLogo ? pxToRem(headerH + 20) : pxToRem(headerH - 20))
export const MaLogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: ${pxToRem(10)};
  left: 50%;
  margin-left: ${pxToRem(logoW / -2)};
  width: ${pxToRem(logoW)};
  height: ${maLogoHeight};
  pointer-events: none;
  z-index: ${zIndexes.ui3};
  & span {
    width: ${pxToRem(logoW / 1.5)};
    height: 140%;
  }
  @media (max-width: ${mABP}) {
    & span {
      max-height: 100%;
    }
  }
`
export const MaImgLogoContainer = styled(BackgroundImage)`
  background-image: url(${maLogo});
  position: absolute;
  top: ${pxToRem(40)};
  left: 0;
  width: 100%;
  height: ${pxToRem(88)};
  z-index: ${zIndexes.ui3};
  margin: 0 auto;
`
export const MaHeader = styled.header`
  color: #fff;
`
export const MaHWeatherIcon = styled.span`
  margin-right: ${pxToRem(8)};
  display: flex;
  align-items: center;
  img {
    height: ${pxToRem(15)};
    width: ${pxToRem(15)};
    filter: invert(1) contrast(2);
  }
`
export const MaRedHeaderGrad = styled.div`
  background-color: ${maColorPrimary};
  background-image: radial-gradient(50% 50%, #ffffff 50%, ${maColorPrimary} 100%);
  height: ${pxToRem(3)};
`
export const MaBlueHeader = styled.div`
  background-color: ${maColorSecondary};
  height: ${pxToRem(headerH - 34 - 3)};
  @media (min-width: ${mABP}) {
    background-color: transparent;
  }
`
export const MaBracketHeader = styled.div`
  background-color: #0f1c24;
  height: ${pxToRem(34)};
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
`
export const MaRedHeader = styled.div`
  background-color: ${maColorPrimary};
  background-image: radial-gradient(50% 50%, ${maColorPrimary} 50%, ${maColorPrimary} 100%);
  height: ${pxToRem(34)};
  display: flex;
  flex-flow: row nowrap;
  font-size: ${pxToRem(13)};
  letter-spacing: ${pxToRem(0.1)};
`
export const MaHGameTime = styled.div`
  font-weight: ${fontWeight.semiBold};
  flex: 1;
  padding: 0 ${pxToRem(16)};
  line-height: ${pxToRem(34)};
  @media (max-width: ${mABP}) {
    display: none;
  }
`
export const MaHGameWeather = styled.div`
  flex: 1;
  text-align: right;
  padding: 0 ${pxToRem(16)};
  line-height: ${pxToRem(34)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${mABP}) {
    display: none;
  }
`
export const MaCloseBtn = styled.button`
  width: ${pxToRem(34)};
  height: ${pxToRem(34)};
  flex: 0 1 ${pxToRem(34)};
  color: #fff;
  transition: all 0.2s ease-in-out;
  will-change: opacity;
  z-index: ${zIndexes.ui3 + 2};
  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.7;
  }
  @media (max-width: ${mABP}) {
    position: absolute;
    top: ${pxToRem(4)};
    right: ${pxToRem(4)};
  }
`
