import * as React from "react"
import styled, { keyframes } from "styled-components/macro"
import allAccessAdSrc from "../../../assets/all-access-ad-300x600.jpg"
import { ButtonBase, OpacityBtn } from "../../../components/Button"
import CircleSvg from "../../../components/icons/Circle"
import Link from "../../../components/Link"
import CardBody from "../../../shared/atoms/Card/CardBody"
import CardDivider from "../../../shared/atoms/Card/CardDivider"
import CardHeader from "../../../shared/atoms/Card/CardHeader"
import CardRow from "../../../shared/atoms/Card/CardRow"
import CardWrapper from "../../../shared/atoms/Card/CardWrapper"
import { breakpoints, fontWeight, layout, palette, pxToRem, skyboxH, zIndexes } from "../../../utils/style-utils"
import { mABP } from "./MatchupAnalysis.styles"

const pulseBorder = keyframes`
0% {
  border-color: ${palette.gray3};
}
25% {
  border-color: ${palette.gray3};
}
50% {
  border-color: ${palette.blue2}b0;
}
75% {
  border-color: ${palette.gray3};
}
100% {
  border-color: ${palette.gray3};
}
`
const bounceDown = keyframes`
0% {
  transform: translateY(-9%);
}
50% {
  transform: translateY(9%);
}
100% {
  transform: translateY(-9%);
}
`
const bounceLeft = keyframes`
0% {
  transform: translateX(-9%) rotate(90deg);
}
50% {
  transform: translateX(9%) rotate(90deg);
}
100% {
  transform: translateX(-9%) rotate(90deg);
}
`
const stroke = keyframes`
100% {
  stroke-dashoffset: 0;
}
`
const scale = keyframes`
0%, 100% {
  transform: none;
}
50% {
  transform: scale3d(1.3, 1.3, 1);
}
`
const fill = keyframes`
100% {
  box-shadow: inset 0px 0px 0px 30px currentColor;
}
`

const cardSpacer = pxToRem(16)
const cardRowH = pxToRem(32)
const leftColW = 320
export const PicksSubmitBtnContainerStyled = styled.div`
  & .picks-submit__container:empty {
    display: none;
  }

  & .picks-submit__footer-right > * {
    margin-left: ${pxToRem(16)};
  }

  & .picks-submit__picks-count {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(12)};
    color: #13161a;
  }

  & .picks-submit__fill-bar-container {
    height: ${pxToRem(4)};
    border-radius: ${pxToRem(9)};
    min-width: 25vw;
    background-color: #eef1f3;
    overflow: hidden;
    position: relative;
  }
  & .picks-submit__fill-bar {
    transition: all 0.4s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${palette.blue2};
  }
  & .picks-submit__picks-count {
    text-align: center;
    padding-bottom: ${pxToRem(8)};
  }
  & .picks-submit__tiebreaker-form {
    margin-bottom: 0;
  }
  & .picks-submit__submit-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  & .picks-submit__submit-make-picks-cta {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    line-height: 1.4em;
    margin-right: auto;
    & strong {
      font-weight: ${fontWeight.bold};
    }
    & svg {
      margin-right: 1rem;
      font-size: 1.2rem;
    }
  }

  & .picks-submit__footer-toggle-pick-slip-cta {
    flex: 1;
    padding: ${pxToRem(16)} 0;
  }

  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      pointer-events: none;
      margin: 0;
    }

    & .picks-submit__footer-toggle-pick-slip-cta {
      display: none;
    }
    & .picks-submit__toggle-tiebreaker-btn {
      display: none;
    }
    & .show-both-btns--true {
      margin-left: ${pxToRem(8)} !important;
    }
    & .picks-submit__footer-right {
      display: none;
    }
    & .picks-submit__wrapper {
      position: relative;
      max-width: ${pxToRem(layout.pageContentMaxW)};
      max-width: var(--page-max-w);
      margin: 0 auto;
    }
    & .picks-submit__container {
      position: absolute;
      left: ${pxToRem(layout.pageGutterW)};
      left: var(--grid-margin);
      bottom: 0;
      margin: 0;
      width: ${pxToRem(leftColW)};
      pointer-events: all;
      background: ${palette.white};
      border: ${pxToRem(1)} solid ${palette.gray3};
      box-shadow: 0 ${pxToRem(-3)} ${pxToRem(6)} -${pxToRem(3)} rgba(0, 0, 0, 0.06);
    }
    & .picks-submit__submit-btn {
      margin: ${pxToRem(16)} auto;
    }
    & .picks-submit__tiebreaker-form {
      padding-top: 0;
    }
    & .picks-submit__tiebreaker-form > * {
      padding-top: ${pxToRem(16)};
    }
    & .picks-submit__tiebreaker-cap {
      display: none;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    /* hide / show tiebreaker logic */
    & .picks-submit__container {
      position: relative;
    }
    & .picks-submit__tiebreaker {
      transition: all 0.2s ease-out;
    }
    /* non-sticky footer styles */
    &.is-bottom-sticky-pick-slip--false {
      & .picks-submit__toggle-tiebreaker-btn {
        display: none;
      }
      & .picks-submit__tiebreaker-cap {
        display: none;
      }
      & .picks-submit__footer-toggle-pick-slip-cta {
        display: none;
      }
    }
    /* sticky footer styles */
    &.is-bottom-sticky-pick-slip--true {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0px -3px ${pxToRem(15)} ${pxToRem(3)} rgba(0, 0, 0, 0.06);
      z-index: ${zIndexes.ui1};
      background-color: ${palette.white};
      transition: transform 0.2s ease-out;
      &.bottom-sticky-pick-slip-mode--hidden {
        transform: translateY(100%);
      }
      &.bottom-sticky-pick-slip-mode--skinny .picks-submit__submit-container {
        min-height: ${pxToRem(45)};
        height: ${pxToRem(45)};
      }
    }
    & .picks-submit__counter {
      display: inline-flex;
      font-weight: bold;
      width: ${pxToRem(17)};
      height: ${pxToRem(17)};
      font-size: ${pxToRem(12)};
      background-color: ${palette.blue60};
      border-radius: 50%;
      color: ${palette.white};
      justify-content: center;
      align-items: center;
      margin-left: ${pxToRem(4)};
    }
    .has-ad--true &.is-custom--true.is-ios--false button {
      bottom: ${pxToRem(layout.mobileBottomAdH + 8)};
    }
    &.is-bottom-sticky-pick-slip--true {
      &.is-mobile-tiebreaker-hidden--true {
        border-top-left-radius: ${pxToRem(16)};
        border-top-right-radius: ${pxToRem(16)};
      }
      &.is-mobile-tiebreaker-hidden--true .picks-submit__tiebreaker-container {
        pointer-events: none;
      }
      &.is-mobile-tiebreaker-hidden--true .picks-submit__tiebreaker {
        transform: translateY(100%);
      }
      & .picks-submit__submit-container {
        min-height: ${pxToRem(40 + layout.pageGutterW * 2)};
        min-height: calc(${pxToRem(40)} + var(--grid-gap) * 2);
        padding: 0 ${pxToRem(16)};
      }
      & .picks-submit__tiebreaker-container {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        z-index: ${zIndexes.ui1};
        overflow: hidden;
        /* give room for box shadow */
        padding-top: ${pxToRem(16)};
      }
      & .picks-submit__tiebreaker-cap {
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.06);
        background: ${palette.gray6};
        border-top-left-radius: ${pxToRem(8)};
        border-top-right-radius: ${pxToRem(8)};
        padding: ${pxToRem(16)} ${pxToRem(16)} 0;
        color: ${palette.default};
      }
      & .picks-submit__tiebreaker-cap strong {
        font-weight: ${fontWeight.bold};
      }
      & .picks-submit__tiebreaker-cap p {
        font-size: 0.9em;
        line-height: 1.5em;
      }
      & .picks-submit__tiebreaker-cap * {
        padding-bottom: ${pxToRem(8)};
      }
      /* submit button parlay: */
      & .picks-submit__toggle-tiebreaker-btn.show-toggle-tiebreaker--false {
        display: none;
      }
      & .picks-submit__submit-btn.show-toggle-tiebreaker--true:not(.show-both-btns--true) {
        display: none;
      }
    }
    /* non-sticky styles */
    &.is-bottom-sticky-pick-slip--false .picks-submit__submit-container {
      padding: ${pxToRem(16)};
    }
    &.is-bottom-sticky-pick-slip--false .picks-submit__tiebreaker-container > * {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }
    &.is-bottom-sticky-pick-slip--false .picks-submit__tiebreaker-container {
      border-bottom: none !important;
    }
    &.is-bottom-sticky-pick-slip--false .picks-submit__container {
      background: ${palette.white};
      border-bottom: ${pxToRem(1)} solid ${palette.gray3};
      margin-bottom: ${pxToRem(16 * 3)};
    }
  }
`
export const CtaModuleWrapper = styled.section`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(400)};
  flex: 1;
  max-height: 100%;
  color: ${palette.gray2};
  padding: ${pxToRem(32)} ${pxToRem(32)} 0;
  text-align: center;
  line-height: 1.5em;
  & > * {
    margin-bottom: ${pxToRem(32)};
  }
  & svg {
    color: ${palette.gray3};
    height: ${pxToRem(100)};
    width: 100%;
    pointer-events: none;
  }
`
// export class CtaModule extends React.PureComponent<any> {

// }
export const CtaModule = ({ iconSvg = null as null | any, children, cta = null as null | any, ...rest }) => (
  <CtaModuleWrapper {...rest}>
    {iconSvg || <CircleSvg />}
    <div>{children}</div>
    {cta}
  </CtaModuleWrapper>
)

export const PicksPageHeaderStyles = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: ${pxToRem(layout.pageGutterW)} 0;
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      padding: 0 ${pxToRem(layout.pageGutterW)};
    }
  }
  & > * {
    white-space: nowrap;
    line-height: ${pxToRem(20)};
  }
  & h3 {
    font-size: ${pxToRem(20)};
    color: #13161a;
  }
  & div {
    font-weight: ${fontWeight.extraBold};
    font-size: ${pxToRem(14)};
    color: #575f66;
  }
`
export const PicksPageHeader = ({ left, right, className = "" }) => (
  <PicksPageHeaderStyles className={className}>
    <h3>{left}</h3>
    <div>{right}</div>
  </PicksPageHeaderStyles>
)

export const PicksParlayBonusStyled = styled.div`
  transition: all 0.2s ease-out;
  padding-bottom: 0;
  margin-bottom: 0;
  & .fields-container {
    position: relative;
  }

  &.is-enabled--false {
    position: relative;
    background-color: ${palette.gray4};
    border: ${pxToRem(1)} solid ${palette.gray3};
    & .fields {
      width: 100%;
      bottom: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
  & .last-answer-help {
    position: absolute;
    width: 3em;
    height: 3em;
    top: -100%;
    right: -1.5em;
    padding: 1em;
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    &.is-enabled--false .fields {
      position: absolute;
      padding: 0;
    }
    &.is-disabled--true.is-enabled--true.is-minimal--true.is-custom--true {
      margin-bottom: 0;
    }
  }
  &.is-minimal--true {
    border-left: none;
    border-right: none;
  }
  &.is-custom--false {
  }
  & * {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .row {
    display: flex;
    flex-flow: row nowrap;
  }
  & .img {
    height: ${pxToRem(32)};
    flex: 0 0 ${pxToRem(45)};
    margin-right: ${pxToRem(12)};
  }
  & .flex1 {
    flex: 1;
  }
  & .f14 {
    font-size: ${pxToRem(14)};
  }
  & .sub {
    font-size: ${pxToRem(11)};
    line-height: ${pxToRem(13)};
  }
  & .gray {
    color: ${palette.gray1};
  }
  & .bold {
    font-weight: ${fontWeight.bold};
  }
  & .algCenter {
    align-items: center;
  }
  & .jstAround {
    justify-content: space-around;
  }
  & .jstCenter {
    justify-content: center;
  }
  & .flxcol {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
  }
  & .txtCtr {
    text-align: center;
  }
  & > * {
    padding: 0 ${pxToRem(16)} ${pxToRem(12)};
  }
  & .fields-container > * {
    padding-bottom: 1em;
  }
  & .fields {
    transition: all 0.2s ease-out;
    max-width: ${pxToRem(450)};
    margin-left: auto;
    margin-right: auto;
    font-size: 0.85em;
    padding: 1em 1em 0;
  }
  & .col {
    flex-flow: column;
    & img {
      margin: 0;
    }
  }
  & .col-rev {
    flex-flow: column-reverse;
    & img {
      margin: 0;
    }
  }
  & .field.has-value--true .DropdownToggle {
    border-color: ${palette.blue2};
  }
  & .field.has-value--true:not([disabled]) > input:not([disabled]) {
    border-color: ${palette.blue2};
  }
  & .field.has-value--true:not([disabled]) button:not([disabled]) {
    border-color: ${palette.blue2};
  }
  &.is-custom--true .field.has-value--false > *:not(:focus) {
    animation: ${pulseBorder} 4s infinite;
  }
  & .Dropdown {
    width: 100%;
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    &.in-modal--true {
      border: none;
    }
  }
`

const CardCalloutStyled = styled(CardWrapper)`
  font-size: ${pxToRem(12)};
  color: ${palette.default};
  & hr {
    margin-bottom: ${pxToRem(layout.pageGutterW)};
    margin-bottom: var(--grid-gap);
  }
  & h4 {
    font-size: ${pxToRem(14)};
  }
  & strong {
    font-weight: ${fontWeight.bold};
  }
  & li {
    height: auto;
  }
  & ul {
    margin-bottom: var(--grid-gap);
  }
`
export const UpsellDisclaimer = styled.small`
  padding: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(32)};
  color: #000;
  display: block;
  font-size: 0.8em;
  & a {
    text-decoration: underline;
  }
`

const UpsellCardStyled = styled(CardBody)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.3em;
  & strong {
    font-weight: ${fontWeight.bold};
  }
  & svg {
    max-height: ${pxToRem(200)};
  }
  & > *:not(:first-child) {
    margin-top: ${pxToRem(layout.pageGutterW)};
    margin-top: var(--grid-gap);
    padding: 0 ${pxToRem(layout.pageGutterW)};
    padding: 0 var(--grid-gap);
  }
`

export const CalloutWithOpaque = styled.section`
  & {
    padding: ${pxToRem(9 + 9)} ${pxToRem(16)} ${pxToRem(9)};
    background-color: ${palette.blue4};
    border: ${pxToRem(1)} solid ${palette.gray3};
    border-radius: ${pxToRem(5)};
    line-height: 1.4;
  }
  & a {
    color: ${palette.blue1};
  }
  & li {
    font-size: 0.9em;
  }
  & p,
  section,
  header,
  ul,
  ol,
  footer {
    margin-bottom: ${pxToRem(9)};
  }
  & strong {
    font-weight: ${fontWeight.bold};
  }
  & header {
    font-size: 1.2em;
    color: ${palette.neutral70};
  }
  & ul,
  ol {
    padding-left: 1em;
  }
  & .flex-row {
    display: flex;
    flex-flow: row nowrap;
  }
  & .justify-content--space-between {
    justify-content: space-between;
  }
  &.font-size--small {
    font-size: 0.875rem;
  }
  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      margin: ${pxToRem(16)};
    }
  }
`

export const UpsellCard = ({ children, ...rest }) => (
  <CardWrapper {...rest}>
    <UpsellCardStyled>{children}</UpsellCardStyled>
  </CardWrapper>
)

const sportsLineStyles = { height: pxToRem(490), overflow: "hidden" }
const allAccessAdStyles = { height: pxToRem(600), width: pxToRem(300) }
export const CardModule = ({ children, title, ...rest }) => (
  <CardWrapper {...rest}>
    <CardHeader>{title}</CardHeader>
    <CardDivider />
    <CardBody>{children}</CardBody>
  </CardWrapper>
)
export const SportslineAd = (props) => <CardModule style={sportsLineStyles} title="SportsLine Picks" {...props} className="sportsline-ad" />
export const AllAccessAd = (props) => (
  <OpacityBtn as={Link} to="https://www.cbs.com/all-access/?ftag=AAM-01-10ahc8h" {...props}>
    <img src={allAccessAdSrc} style={allAccessAdStyles} alt="all-access" loading="lazy" />
  </OpacityBtn>
)
interface ICardCallout extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  headerImg?: null | any
  header: any
  items: any[]

  children?: React.ReactNode
}
export const CardCallout = ({ headerImg = null, header, items, children, ref, className, ...rest }: ICardCallout) => {
  const classNames = [`card-callout`]
  if (className) {
    classNames.push(className)
  }
  return (
    <CardCalloutStyled className={classNames.join(" ")} {...rest}>
      <CardHeader>
        {headerImg}
        <h4>{header}</h4>
      </CardHeader>
      <CardDivider />
      {(items.length && (
        <ul>
          {items.map(({ dt, dd }, i) => (
            <CardRow key={i} as="li">
              <span>
                {(dt && <strong>{dt}&nbsp;</strong>) || ""}
                {dd || ""}
              </span>
            </CardRow>
          ))}
        </ul>
      )) ||
        null}
      {children}
    </CardCalloutStyled>
  )
}

export const AnimatedSaveStyles = styled.div`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${palette.blue2};
  background: ${palette.white};
  transition: all 0.4s cubic-bezier(0.65, 0, 0.45, 1);
  pointer-events: none;
  & svg {
    height: 1.5em;
    width: 1.5em;
  }
  & div {
    padding: 0.5em;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.45, 1);
  }
  & .checkmark {
    stroke: ${palette.white};
    color: ${palette.blue2};
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.65, 0, 0.45, 1);
    border-radius: 50%;
  }
  & .checkmark__check {
    fill: ${palette.white};
    transition: all 0.2s cubic-bezier(0.65, 0, 0.45, 1);
  }
  &.is-saving--false {
    background-color: transparent;
    border-color: transparent;
  }
  &.is-saving--false div {
    opacity: 0;
    transform: translateX(15%);
  }
  &.is-saving--true .checkmark {
    opacity: 1;
    animation: ${fill} 0.3s ease-in-out 0.1s forwards, ${scale} 0.4s ease-in-out 0.1s both;
  }
  &.is-saving--true .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${palette.blue2};
    fill: none;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
`

export const PickemEventMatchStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & > * {
    margin-right: 0.5em;
  }
  & small {
    font-size: 0.8em;
    @media (max-width: 400px) {
      & {
        display: none;
      }
    }
  }
`

export const PickemEventSpreadChangeStyled = styled.div`
  color: ${palette.gray1};
  font-size: 0.7em;
  letter-spacing: 0.02em;
  font-weight: ${fontWeight.semiBold};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 auto;
  white-space: nowrap;
  & div {
    margin-right: 0.5em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @media (max-width: 350px) {
      & {
        margin-right: 0.25em;
      }
    }
  }
  div:last-child {
    height: ${pxToRem(13)};
    width: ${pxToRem(13)};
  }
  .cardactions__prop-line-value {
    margin-left: ${pxToRem(3)};
    & svg {
      font-size: ${pxToRem(6)};
    }
  }
  .cardactions__prop-line-value--better {
    background-color: ${palette.green}33;
    color: ${palette.green};
    margin-left: ${pxToRem(3)};
    padding: ${pxToRem(2)} ${pxToRem(3)};
  }
  .cardactions__prop-line-value--worse {
    background-color: ${palette.red}33;
    color: ${palette.red};
    padding: ${pxToRem(2)} ${pxToRem(3)};
  }
  & .information-outline-svg {
    height: ${pxToRem(13)};
    width: ${pxToRem(13)};
    color: ${palette.black};
    margin-bottom: ${pxToRem(1)};
  }
  &:after {
    background-color: ${palette.black}08;
  }
`

export const EventRowStyled = styled.div`
  background: #fff;
  border: ${pxToRem(1)} solid ${palette.gray3};
  margin-bottom: ${cardSpacer};
  margin-bottom: var(--grid-gap);
  padding-top: ${cardSpacer};
  position: relative;

  & > * {
    padding-bottom: ${cardSpacer};
  }
  & > hr {
    margin: 0 ${pxToRem(16)} 0;
    height: ${pxToRem(1)};
    background-color: ${palette.gray3};
    padding-bottom: 0;
  }
  & .cardactions {
    height: ${pxToRem(48)};
    display: flex;
    flex-flow: row nowrap;
    padding-bottom: 0;
    position: relative;
    & > * {
      height: 100%;
      flex: 1;
    }
  }
  & .cardheader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 ${cardSpacer} ${cardSpacer};
    font-weight: ${fontWeight.semiBold};
    color: ${palette.gray1};
    font-size: ${pxToRem(11)};
  }
  & .cardheader > * {
    display: flex;
    flex-flow: row nowrap;
  }
  & i {
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
    font-weight: ${fontWeight.bold};
    color: ${palette.blue1};
    font-size: ${pxToRem(13)};
  }
  & b {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    padding: 0.3em 0.5em;
    margin-top: -0.6em;
    background: ${palette.orange};
    color: white;
    font-size: 0.8em;
    text-transform: uppercase;
    border-radius: 0.3em;
  }
  & b svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
    margin-top: -0.1em;
  }
  & .cardheader i svg {
    width: 1em;
    height: 1em;
    padding-bottom: 0.15em;
    margin-left: 0.15em;
  }

  & .cardheader__match {
    font-size: ${pxToRem(16)};
  }

  & .cardbody {
    display: flex;
    flex-flow: row nowrap;
  }
  & .cardbodyright {
    flex: 0 1 ${pxToRem(121)};
    border-left: ${pxToRem(1)} solid ${palette.gray3};
  }
  & .cardbodyleft {
    flex: 1 1 100%;
  }
  & .cardbodyrow {
    display: flex;
    flex-flow: row nowrap;
    height: ${cardRowH};
    margin-bottom: ${cardSpacer};
    padding: 0 ${cardSpacer};
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    & button small {
      padding-left: ${pxToRem(6)};
    }
  }
  &.uses-weights--true.needs-dnd-mode--false .cardbodyleft .cardbodyrow {
    padding-left: 0;
  }
  & .cardheader__pts-desc {
    transition: all 0.4s ease-out;
  }

  & .cardbodyleftaction[disabled] {
    pointer-events: none;
    opacity: 0;
  }
  &.is-picked--false .cardbodyleftaction {
    pointer-events: none;
    opacity: 0;
  }
  &.is-picked--false .cardactions__group {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    transform: scale(1.1);
  }
  &.is-picked--false .cardheader__pts-desc {
    opacity: 0;
    transform: scale(1.1);
  }

  & .cardactions__ma-btn {
    letter-spacing: 0.01em;
    white-space: nowrap;
  }
  & .cardactions__group {
    display: flex;
    flex-flow: row nowrap;
    border-right: ${pxToRem(1)} solid ${palette.gray3};
    flex: 0 1 auto;
    position: relative;
    transition: all 0.2s ease-out;
  }
  & .cardactions__align-top {
    width: 3rem;
    justify-content: center;
    color: ${palette.default};
    font-size: ${pxToRem(11)};
    &:disabled {
      opacity: 0.4;
    }
  }
  & .cardactions__divider {
    max-width: ${pxToRem(2)};
    min-width: ${pxToRem(2)};
    height: 70%;
    margin-top: 5%;
    background-color: rgba(0, 0, 0, 0.05);
  }
  @media (hover: hover) {
    & .cardactions__align-top:hover {
      background-color: ${palette.blue4};
    }
  }
  & .cardactions__align-top:active {
    background-color: ${palette.blue4};
  }
  & .cardactions__group .Dropdown {
    height: 100%;
  }
  & .cardactions__group .Dropdown-listContainer {
    height: 100%;
  }
  & .cardactions__group .Dropdown-listContainer {
    height: 100%;
  }
  & .cardactions__group .Dropdown-selectedText {
    border: none;
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  & .cardactions__group .Dropdown--mobile .DropdownToggle {
    height: 100%;
    border: none;
  }
  & .game-title-container {
    position: absolute;
    height: ${pxToRem(24)};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    left: 0;
    right: 0;
    bottom: -${pxToRem(12)};
  }
  & .game-title {
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(13)};
    text-align: center;
    background: white;
    border-radius: ${pxToRem(2)};
    padding: ${pxToRem(3)} 3vw;
    box-shadow: 0px 0px ${pxToRem(3)} ${pxToRem(1)} #00000014;
  }

  & .is-dragging--true {
    cursor: grabbing !important;
  }
  & .cardbodyleftaction {
    justify-content: center;
    cursor: grab;
    padding-left: ${cardSpacer};
    padding-right: ${cardSpacer};
  }
  & .cardteam .team-img {
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
  }
  & .cardteam svg.team-img {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
  & .cardteam > * {
    margin-right: ${pxToRem(6)};
    font-weight: ${fontWeight.semiBold};
    font-size: ${pxToRem(14)};
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & .cardteam > i {
    font-weight: ${fontWeight.semiBold};
    color: ${palette.gray2};
    font-size: ${pxToRem(11)};
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    width: 2.25rem;
    line-height: 14px;
  }
  & .cardteam > .cardteam__team-rank {
    font-size: ${pxToRem(12)};
    font-weight: ${fontWeight.semiBold};
    color: ${palette.gray2};
    text-align: right;
    min-width: 1.25em;
    max-width: 1.25em;
    margin-right: 0.3em;
  }
  & .cardteam > .team-name {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  & .cardteam > .picks-percentage,
  & .cardteam > .score,
  & .cardteam > .record {
    width: 2rem;
    display: flex;
    justify-content: flex-end;
  }
  & .cardaction button {
    position: relative;
  }
  & .cardaction small {
    /* prevent overflow on pickem cards */
    white-space: nowrap;
  }
  & .animated-save {
    top: ${pxToRem(-2)};
    left: ${pxToRem(-2)};
    right: ${pxToRem(-2)};
    bottom: ${pxToRem(-2)};
    background: rgb(245, 250, 255);
    border: ${pxToRem(1)} solid ${palette.gray3};
  }
  & .is-saving--false .animated-save {
    background-color: transparent;
    border-color: transparent;
  }

  & .static-animated-save {
    top: 0;
    height: 4em;
    right: 0;
    width: 30%;
    padding-right: ${pxToRem(12)};
    justify-content: flex-end;
  }
  @media (max-width: ${pxToRem(450)}) {
    & .cardaction {
      padding: 0;
      justify-content: center;
    }
    & .cardbodyright {
      flex: 1 0 37vw;
    }
    & .cardaction button {
      white-space: nowrap;
      min-width: 28vw;
      max-width: 28vw;
      overflow: hidden;
      padding: 0;
      font-size: ${pxToRem(11)};
    }
    & .cardteam > .team-name {
      width: 100px;
      max-width: 100px;
    }
    & .cardbody {
      max-width: 100vw;
      overflow-x: hidden;
    }
  }
  @media (max-width: ${pxToRem(400)}) {
    & .cardbodyright {
      flex: 1 0 30vw;
    }
    & .cardaction button {
      min-width: 22vw;
      max-width: 22vw;
    }
  }

  /* weighting mode */
  &.pickem-event--weighting {
    height: ${pxToRem(64)};
    padding: 0;

    & .Dropdown-selectList,
    & .Dropdown-selectText {
      padding-right: 2em;
    }
    & .cardteam {
      margin: 0;
    }
    & .cardbody {
      padding: 0;
      align-items: center;
    }
    & .cardbodyright {
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      flex: 0 1 ${pxToRem(160)};
      color: ${palette.gray2};
    }
    & .cardbodyright [disabled] {
      opacity: 0.7;
    }
    & .cardbodyright > * {
      margin: 0;
      flex: 0 1 50%;
      height: 100%;
    }
    & [data-movable-handle] {
      cursor: grab;
    }
    & [data-movable-handle] > * {
      pointer-events: none;
    }
    & .weighting__weight {
      width: ${pxToRem(90)};
      min-width: ${pxToRem(90)};
    }
    & .weighting__weight .Dropdown {
      width: 100%;
      border-left-width: 0;
    }
  }

  /* matchup analysis mode */
  &.pickem-event--ma {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    width: 100%;
  }
  & .ma-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    flex: 1 1 50%;
    padding: 0;
    & .cardteam {
      flex: 1;
      padding: 0;

      & > .team-name {
        width: 100px;
      }
    }
    & .cardteam > * {
      padding: ${pxToRem(4)};
      display: flex;
    }
  }
  @media (max-width: ${mABP}) {
    & .ma-row {
      padding-right: ${pxToRem(8)};
    }
    & .ma-row > *:first-child {
      padding: 0 ${pxToRem(8)};
    }
  }
  @media (min-width: ${mABP}) {
    & .ma-row {
      padding: 0 0.5rem;
      width: 50%;
    }
    & .side--away {
      flex-direction: row-reverse;
      & > .cardbodyrow {
        & > button {
          small {
            padding-right: ${pxToRem(6)};
            padding-left: 0;
          }
        }
      }
    }
    & .side--away * {
      flex-direction: row-reverse;
    }
    & .ma-row svg.team-img {
      visibility: hidden;
    }
  }
  @media (max-width: ${pxToRem(360)}) {
    &.uses-weights--true.needs-dnd-mode--false .cardteam > * {
      font-size: ${pxToRem(12)};
    }
    & .cardbodyleftaction {
      padding-left: ${pxToRem(10)};
      padding-right: ${pxToRem(10)};
    }
  }

  /* prop version: */
  &.is-prop--true {
    & .cardheader {
      align-items: center;
    }
    & .cardbody {
      display: block;
      padding-left: ${pxToRem(16)};
      padding-right: ${pxToRem(16)};
    }
    & .cardactions__ma-btn {
      justify-content: flex-start;
      padding: 0 ${pxToRem(16)};
    }
  }
  &.pickem-event__is-disabled--true {
    background-color: ${palette.red}0d;
  }
  & .cardbody__prop-options-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
  }
  & .cardbody__prop-question {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
    font-weight: ${fontWeight.bold};
    margin-bottom: ${pxToRem(16)};
    & b {
      display: inline-flex;
      font-size: 0.5em;
    }
  }
  & .team-event__prop-btn {
    flex: 1;
    border-radius: ${pxToRem(10)};
    height: ${pxToRem(69)};
    border: 1px solid currentColor;
    transition: all 0.2s ease-out;
    &:after {
      background-color: ${palette.blue2}33;
    }
    &[disabled] {
      color: ${palette.gray3};
    }
    &.team-event__prop-btn--unpicked {
      border-color: ${palette.gray3};
    }
    &.team-event__prop-btn--blue {
      color: ${palette.white};
      border-color: ${palette.blue2};
      background-color: ${palette.blue2};
      &:after {
        background-color: ${palette.white}33;
      }
    }
    &.team-event__prop-btn--green {
      border-color: ${palette.green};
    }
    &.team-event__prop-btn--greenHue {
      color: ${palette.gray1};
      background-color: ${palette.green}33;
    }
    &.team-event__prop-btn--red {
      border-color: ${palette.red};
    }
    &.team-event__prop-btn--redHue {
      color: ${palette.gray1};
      background-color: ${palette.red}33;
    }
    &:first-child {
      margin-right: ${pxToRem(16)};
    }
  }
  & .event-team__prop-btn-marker {
    position: absolute;
    left: ${pxToRem(4)};
    top: ${pxToRem(4)};
    font-size: ${pxToRem(14)};
  }
  & .event-team__prop-btn-text {
    font-size: ${pxToRem(18)};
    font-weight: ${fontWeight.semiBold};
    line-height: ${pxToRem(30)};
  }
  & .event-team__prop-btn-perc-owned {
    font-size: ${pxToRem(11)};
    letter-spacing: 0.01em;
  }
  & .cardactions__prop-lines {
    padding: 0 ${pxToRem(16)};
    @media (max-width: ${pxToRem(400)}) {
      & {
        padding-left: 0;
      }
    }
  }
  & .cardactions {
    @media (max-width: ${pxToRem(400)}) {
      & {
        font-size: 0.85em;
      }
    }
  }
`

export const bracketSaveAnimMs = 400

export const BracketForm = styled.form`
  min-height: 50vh;
  flex: 1 1 100%;
  overflow: hidden;
  &[disabled] {
    pointer-events: auto !important;
  }
  &.is-multiple-horiz-sections--false {
    will-change: scroll-position;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: visible;
    max-height: unset;
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) and (min-height: 800px) {
    & {
      /* attempt to make sidebar extend down all the way */
      /* height: calc(98vh - ${skyboxH} - ${pxToRem(layout.siteNavH + layout.siteSubNavH + layout.pageMarqueeMinH)}); */
      /* but set a max height for really tall screens of course */
      max-height: ${pxToRem(layout.maxBracketH)};
    }
  }
`

const inputGroupH = 50
const submitBtnPaddingH = 16
const desktopWarningH = 40
// visually looks better not flush with top of el
const desktopWarningOffsetTop = 2

export const WarningStateEl = styled.div`
  & {
    position: absolute;
    color: black;
    transition: all 0.4s ease-out;
    opacity: 0;
    pointer-events: none;
    line-height: 1.2;

    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
  }
  &:before {
    content: "";
    position: absolute;
    left: ${pxToRem(-5)};
    top: 0;
    width: 0;
    height: 0;
    border-top: ${pxToRem(desktopWarningH / 2)} solid transparent;
    border-bottom: ${pxToRem(desktopWarningH / 2)} solid transparent;
    border-right: ${pxToRem(desktopWarningH / 2)} solid ${palette.yellow};
    clear: both;
  }
  & strong {
    font-weight: ${fontWeight.bold};
  }
  &.is-outside-form--false {
    position: sticky;
    top: unset;
    bottom: 0;
    padding: ${pxToRem(8)} 0;
  }
  &.is-warning-state--true {
    opacity: 1;
  }
  & svg {
    width: ${pxToRem(32)};
    margin-right: ${pxToRem(14)};
  }

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      top: -120%;
      backdrop-filter: blur(2px);
      border-top-left-radius: ${pxToRem(4)};
      border-top-right-radius: ${pxToRem(4)};
      height: 120%;
      width: 100%;
      left: 0;
    }
    &:before {
      display: none;
    }
    & svg {
      animation: ${bounceDown} 2s infinite;
      margin-right: ${pxToRem(8)};
    }
    &.is-mobile-in-view--true {
      opacity: 0;
    }
    /* hide for sticky footer version */
    &.is-custom-tiebreaker--true {
      display: none;
    }
    &.is-rotated--true {
      position: static;
      padding: 0;
      justify-content: flex-start;
      & svg {
        transform: rotate(180deg);
        animation: none;
      }
    }
  }

  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & {
      top: -${pxToRem(2.0 * inputGroupH - desktopWarningOffsetTop)};
      left: 100%;
      left: calc(100% + ${pxToRem(8)});
      color: ${palette.black};
      background-color: ${palette.yellow};
      height: auto;
      padding: ${pxToRem(8)} ${pxToRem(14)} ${pxToRem(8)} ${pxToRem(8)};
      min-height: ${pxToRem(desktopWarningH)};
      box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.1);
      border-radius: ${pxToRem(3)};
      &.is-custom-tiebreaker--true {
        top: -${pxToRem(3.0 * inputGroupH - desktopWarningOffsetTop)};
      }
    }
    &.is-warning-state--true.all-feilds-filled--false {
      transition-delay: 0.5s;
    }
    &.is-warning-state--true.all-feilds-filled--true.is-dirty--true {
      transform: translateY(${pxToRem(2.0 * inputGroupH + submitBtnPaddingH)});
      &.is-custom-tiebreaker--true {
        transform: translateY(${pxToRem(3.0 * inputGroupH + submitBtnPaddingH)});
      }
    }
    &.is-outside-form--false {
      display: none;
    }
    & svg {
      animation: ${bounceLeft} 2s infinite;
      width: 1.1em;
      height: 1.1em;
      margin: 0;
    }
    & span {
      min-width: ${pxToRem(185)};
      margin-left: 0.5em;
    }
  }
`

export const MainSaveBtn = styled(ButtonBase)`
  margin: 0 auto;
  max-height: ${pxToRem(40)};
  padding: 0;
  max-width: 90%;

  &[disabled].is-submitting--false {
    background-color: ${palette.gray4};
    border-color: ${palette.gray3};
    color: ${palette.gray2};
  }

  &.is-warning-state--true .warning-state-el {
    opacity: 1;
  }

  &.is-link-version--true {
    background-color: transparent !important;
    border-color: transparent !important;
    color: ${palette.blue1};
    &:active {
      color: ${palette.blue2};
    }
  }
  @media (hover: hover) {
    &.is-link-version--true:hover {
      color: ${palette.blue2};
    }
  }

  /*
  &.is-change-version--true {
    background-color: ${palette.orange};
    border-color: ${palette.orange};
    color: ${palette.white};
    &:active {
      background-color: ${palette.orange};
      border-color: ${palette.orange};
    }
  }
  @media (hover: hover) {
    &.is-change-version--true:hover {
      background-color: ${palette.orange};
      border-color: ${palette.orange};
    }
  }
  */

  @media (max-width: ${pxToRem(breakpoints.handheld)}) {
    &.is-mobile-in-view--true .warning-state-el {
      opacity: 0;
    }

    &.is-change-version--true.is-mobile-tiebreaker-hidden--true {
      display: none;
    }
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    &.is-warning-state--true.all-feilds-filled--false .warning-state-el {
      transition-delay: 0.5s;
    }
    &.is-warning-state--true.all-feilds-filled--true.is-dirty--true .warning-state-el {
      transform: translateY(${pxToRem(195)});

      &.is-custom-tiebreaker--true {
        transform: translateY(${pxToRem(272)});
      }
    }
  }
`

export const OptionsMenu = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 100%;
  max-width: 90rem;
  &:not(:empty) {
    margin-bottom: var(--grid-gap);
  }
  & > * {
    margin-right: 1rem;
  }
  & .members-dropdown {
    width: ${pxToRem(160)};
  }
  & .members-dropdown:empty {
    display: none;
  }

  & .manager-mode-toggle {
    background: #ffffff;
    border: 1px solid #dae0e5;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0.625rem 0 0.625rem 1rem;
  }

  & .manager-mode-toggle.single-bracket-area {
    height: 2.25rem;
  }

  .print-bracket {
    margin-left: auto;
    margin-right: 0;
  }
`

export const TiebreakLabelWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > .tb-title {
    font-weight: ${fontWeight.regular};
    color: ${palette.gray40};
    margin-top: 0.25rem;
    font-size: 0.75rem;
  }
  & > .tb-label {
    font-weight: ${fontWeight.bold};
  }
`

export const TiebreakLabel = styled.span`
  display: flex;
  font-weight: ${fontWeight.bold};
  color: ${palette.white};
  background-color: ${palette.red};
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.125rem;
`
