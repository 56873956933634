import React from "react"
import { teamImgUrlWithFallback, teamLongName } from "../utils/data-utils"
import HelmetSvg from "./icons/Helmet"

class TeamImg extends React.PureComponent<any> {
  public render() {
    const { team, canUseTeamLogos, className, style, alt } = this.props
    const props = {
      className: `team-img ${className || ""}`,
      style,
    }
    if (canUseTeamLogos && team) {
      return <img {...props} alt={alt || (team && teamLongName(team, canUseTeamLogos))} src={teamImgUrlWithFallback(team)} loading="lazy" />
    }
    // console.dir(team)
    const color = `#${(team && team.colorHexDex) || "eaeaea"}`
    // const color = `#${team && team.colorHexDex || "eaeaea"}eb`;
    return <HelmetSvg {...props} outlineColor={`#000000`} className={`team-img ${className || ""}`} style={style} color={color} />
  }
}

export default TeamImg
