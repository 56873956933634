import * as React from "react"
import styled from "styled-components/macro"
import { pxToRem, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const cardSpacer = pxToRem(16)

const StyledCardWrapper = styled.div`
  background: ${palette.white};
  border: ${pxToRem(1)} solid ${palette.solitudeGrey};
  margin-bottom: ${cardSpacer};
  padding-top: ${cardSpacer};
`

const StyledRoundedCardWrapper = styled(StyledCardWrapper)`
  border: none;
  box-shadow: 0 0 0.125rem 0 ${palette.gray75};
  border-radius: 0.5rem;
`

interface ICardWrapperProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: "rounded" | "squared"
}

const CardWrapper = ({ variant, children, ref, className, ...rest }: ICardWrapperProps) => {
  if (variant === "rounded") {
    return (
      <StyledRoundedCardWrapper className={className} {...rest}>
        {children}
      </StyledRoundedCardWrapper>
    )
  }
  return (
    <StyledCardWrapper className={className} {...rest}>
      {children}
    </StyledCardWrapper>
  )
}
export default CardWrapper
