import styled from "styled-components/macro"
import { pxToRem } from "../../../utils/style-utils"

const cardSpacer = pxToRem(16)
const cardRowH = pxToRem(32)

export const CardRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: ${cardRowH};
  margin-bottom: ${cardSpacer};
  padding: 0 ${cardSpacer};
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`
export default CardRow
